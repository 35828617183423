var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-1",attrs:{"fluid":"","tag":"section"}},[_c('v-card',[_c('v-card-title',{staticClass:"card-title"},[_c('h4',[_vm._v(_vm._s(_vm.$t('branches')))]),_c('v-spacer'),(_vm.addPermission)?_c('v-btn',{attrs:{"rounded":"","color":"primary","small":"","dark":""},on:{"click":_vm.addClick}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1),(_vm.alertEnabled)?_c('alert',{attrs:{"alert-msg":_vm.alertMsg,"alert-type":_vm.alertType}}):_vm._e(),_c('v-data-table',{staticClass:"fill-height elevation-1",staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.mainHeaders,"loading":_vm.loading,"items":_vm.filteredOffices,"items-per-page":_vm.filteredOffices.length,"footer-props":_vm.footerProps,"search":_vm.search,"single-expand":true,"expanded":_vm.expanded,"dense":"","sort-by":"Id","item-key":"Id","fluid":"","fixed-header":"","group-by":"[RegionOfficeName]"},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.expand},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
var items = ref.items;
return [_c('td',{staticClass:"canvas",attrs:{"colspan":headers.length},on:{"click":toggle}},[(isOpen)?_c('v-icon',[_vm._v(" mdi-chevron-up ")]):_c('v-icon',[_vm._v(" mdi-chevron-down ")]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.$t('region'))+": "+_vm._s(group))]),_c('v-chip',{staticClass:"ma-2 text--black",attrs:{"pill":"","x-small":"","color":"white"}},[_vm._v(" "+_vm._s(items.length)+" ")])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{on:{"click":function($event){return _vm.expand(item)}}},[_c('td',[_vm._v(" "+_vm._s(item.BranchOfficeName)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.ContactEmail)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.ContactPhone)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.ContactPerson)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.StatusForDisplay)+" ")])])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"3","md":"3","sm":"3","offset":"8"}},[(!_vm.loading)?_c('v-select',{staticClass:"mt-6",attrs:{"items":_vm.branchStatuses,"loading":_vm.loading,"item-text":"ShortDescription","item-value":"LowValue","dense":"","placeholder":"All","multiple":""},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}}):_vm._e()],1)],1),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.dialog)?_c('manage-branch-office',{attrs:{"dialog":_vm.dialog},on:{"branch-office-dialog-closed":_vm.dialogClosed,"successfully-added-branch-office":_vm.addedBranchOffice}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('areYouSureDeleteBranch'))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteBranchOfficeConfirm}},[_vm._v(" "+_vm._s(_vm.$t('ok'))+" ")]),_c('v-spacer')],1)],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('details-card',{attrs:{"details":_vm.selectedBranch,"type":'stores'},on:{"refresh-branch-offices":_vm.getbranchOffices}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('deleteoffice')))])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }